import { Button } from "@material-ui/core";
import { Switch } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { IconButton, Tooltip } from "@material-ui/core";
import { CheckBox, CheckCircle } from "@material-ui/icons";
import moment from "moment/min/moment-with-locales";
import { useSnackbar } from "notistack";
import { Circle, Edit, Eye } from "react-feather";
import { useNavigate } from "react-router";
import EscolaTaula from "../../../../components/EscolaTaula";
import { changePublicada } from "../../../../database/API";

const ActivitatsColumns = (activitats, filterList) => {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const columns = [
		{
			name: "id",
			label: "id",
			options: {
				filter: false,
				sort: false,
				display: false,
			},
		},
		{
			name: "escola",
			label: "Escola",
			options: {
				filter: false,
				sort: true,
				sortOrder: "asc",
				customBodyRender: (value) => <EscolaTaula value={value} />,
			},
		},

		{
			name: "escola.nom",
			label: "Escola",
			options: {
				filter: true,
				sort: true,
				display: false,
				sortOrder: "asc",
				filterList: filterList,
			},
		},
		{
			name: "nom",
			label: "Nom",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "curs",
			label: "Curs",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "hora_i",
			label: "Hora Inici",
			options: {
				filter: false,
				sort: true,
				sortOrder: "asc",
				customBodyRender: (value) => moment(value, "H:i").format("H:mm"),
			},
		},
		{
			name: "hora_f",
			label: "Hora Final",
			options: {
				filter: false,
				sort: true,
				sortOrder: "asc",
				customBodyRender: (value) => moment(value, "H:i").format("H:mm"),
			},
		},
		{
			name: "dia",
			label: "Dia de la setmana",
			options: {
				filter: true,
				sort: true,
				sortOrder: "asc",
				customBodyRender: (value) => (value ? moment(value, "e").locale("ca").format("dddd") : "Diària"),
			},
		},
		{
			name: "preu",
			label: "Preu",
			options: {
				filter: false,
				sort: true,
				sortOrder: "asc",
				customBodyRender: (value) => value + " €",
			},
		},
		{
			name: "imparteix",
			label: "Qui l'imparteix",
			options: {
				filter: false,
				sort: true,
				sortOrder: "asc",
			},
		},
		{
			name: "pack.nom",
			label: "Pack",
			options: {
				filter: false,
				sort: true,
				sortOrder: "asc",
			},
		},
		{
			name: "places",
			label: "Places",
			options: {
				filter: false,
				sort: true,
				sortOrder: "asc",
				customBodyRenderLite: (dataIndex) => {
					return (
						<div
							style={{
								color: Number(activitats[dataIndex].alumnes_count) >= Number(activitats[dataIndex].places) && "#ea7c7c",
							}}
						>
							{activitats[dataIndex].alumnes_count}/{activitats[dataIndex].places}
						</div>
					);
				},
			},
		},
		{
			name: "publicada",
			label: "Publicada",
			options: {
				filter: false,
				sort: true,
				sortOrder: "asc",
				customBodyRender: (value, tableMeta, updateValue) => {
					return (
						<FormControlLabel
							// label={value === "1" ? "Sí" : "No"}
							value={String(value) === "1" ? "1" : "0"}
							control={<Switch color="primary" checked={String(value) === "1"} value={String(value) === "1" ? "1" : "0"} />}
							onChange={async (event) => {
								await changePublicada(tableMeta.rowData[0]);
								// console.log(tableMeta);
								enqueueSnackbar("Activitat " + tableMeta.rowData[3] + " actualitzada", {
									variant: "success",
								});
								updateValue(String(event.target.value) === "1" ? "0" : "1");
							}}
						/>
					);
				},
			},
		},
		{
			name: "Accions",
			options: {
				filter: false,
				sort: false,
				empty: true,
				customBodyRenderLite: (dataIndex) => {
					return (
						<Tooltip title="Veure">
							<IconButton
								onClick={() =>
									navigate(`/admin/activitats/${activitats[dataIndex].id}`, {
										state: activitats[dataIndex],
									})
								}
							>
								<Eye />
							</IconButton>
						</Tooltip>
					);
				},
			},
		},
	];
	return columns;
};

export default ActivitatsColumns;
