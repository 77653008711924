import React from "react";
import { Box, Grid, Hidden, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import ActivitatWeek from "./ActivitatWeek";

const useStyles = makeStyles((theme) => ({
	wrapDies: {
		display: "flex",
		width: "100%",
	},
	dia: {
		padding: 20,
		"&>h3": {
			color: "white",
		},
	},
	pack: {
		border: "5px solid",
		margin: 0,
		marginTop: 10,
	},
	pack_titol: {
		backgroundColor: "white",
		textAlign: "center",
	},
}));

const ActivitatsWeek = (props) => {
	const classes = useStyles();
	const { activitats, packs, color, values, setFieldValue, setContinuar } = props;
	const matches = useMediaQuery("(min-width:960px)");
	const hores = [...new Set(activitats.map((a) => a.hora_i))];

	const selPack = (activitats) => {
		if (String(activitats[0].publicada) !== "0") {
			let aux = [];

			activitats.map((item) => (aux = [...aux, item.id]));
			let newActivitats = values.activitat;

			aux.map((id) => {
				if (newActivitats?.includes(id)) {
					let newValues = newActivitats.filter(function (element) {
						return element !== id;
					});
					newActivitats = newValues;
				} else {
					newActivitats = [...newActivitats, id];
				}
			});

			setFieldValue("activitat", newActivitats);

			if (newActivitats.length > 0) {
				setContinuar(true);
			} else {
				setContinuar(false);
			}
		}
	};

	return (
		<>
			<Hidden smDown>
				<Grid container spacing={1}>
					<Grid item xs={12} md>
						<Box className={classes.dia} style={{ backgroundColor: color }}>
							<Typography variant="h3">Dilluns</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md>
						<Box className={classes.dia} style={{ backgroundColor: color }}>
							<Typography variant="h3">Dimarts</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md>
						<Box className={classes.dia} style={{ backgroundColor: color }}>
							<Typography variant="h3">Dimecres</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md>
						<Box className={classes.dia} style={{ backgroundColor: color }}>
							<Typography variant="h3">Dijous</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md>
						<Box className={classes.dia} style={{ backgroundColor: color }}>
							<Typography variant="h3">Divendres</Typography>
						</Box>
					</Grid>
				</Grid>
			</Hidden>

			{activitats?.map((item) => {
				return (
					String(item.setmanal) === "1" && (
						<ActivitatWeek color={color} activitat={item} setFieldValue={setFieldValue} values={values} setContinuar={setContinuar} key={item.id} />
					)
				);
			})}
			{packs?.map((item) => {
				return (
					item.activitats.length !== 0 && (
						<Grid container spacing={1} className={classes.pack} style={{ borderColor: color }} onClick={() => selPack(item.activitats)}>
							<Grid item md={12} className={classes.pack_titol}>
								<Typography
									variant="h4"
									style={{
										color: color,
										padding: 5,
										fontSize: 25,
									}}
								>
									{item.nom} - {item.preu} €
								</Typography>
							</Grid>
							<Grid item xs={12} md className={classes.pack_dia}>
								{item.activitats?.map((item) => {
									return (
										String(item.dia) === "1" && (
											<ActivitatWeek
												color={color}
												activitat={item}
												setFieldValue={setFieldValue}
												values={values}
												setContinuar={setContinuar}
												key={item.id}
											/>
										)
									);
								})}
							</Grid>
							<Grid item xs={12} md className={classes.pack_dia}>
								{item.activitats?.map((item) => {
									return (
										String(item.dia) === "2" && (
											<ActivitatWeek
												color={color}
												activitat={item}
												setFieldValue={setFieldValue}
												setContinuar={setContinuar}
												values={values}
												key={item.id}
											/>
										)
									);
								})}
							</Grid>
							<Grid item xs={12} md className={classes.pack_dia}>
								{item.activitats?.map((item) => {
									return (
										String(item.dia) === "3" && (
											<ActivitatWeek
												color={color}
												activitat={item}
												setFieldValue={setFieldValue}
												setContinuar={setContinuar}
												values={values}
												key={item.id}
											/>
										)
									);
								})}
							</Grid>
							<Grid item xs={12} md className={classes.pack_dia}>
								{item.activitats?.map((item) => {
									return (
										String(item.dia) === "4" && (
											<ActivitatWeek
												color={color}
												activitat={item}
												setFieldValue={setFieldValue}
												setContinuar={setContinuar}
												values={values}
												key={item.id}
											/>
										)
									);
								})}
							</Grid>
							<Grid item xs={12} md className={classes.pack_dia}>
								{item.activitats?.map((item) => {
									return (
										String(item.dia) === "5" && (
											<ActivitatWeek
												color={color}
												activitat={item}
												setFieldValue={setFieldValue}
												setContinuar={setContinuar}
												values={values}
												key={item.id}
											/>
										)
									);
								})}
							</Grid>
						</Grid>
					)
				);
			})}
			{matches && (
				<table>
					{hores?.map((h) => (
						<tr>
							<td width={matches ? "20%" : "100%"} style={{ verticalAlign: "top" }}>
								{activitats?.map((item) => {
									return (
										String(item.dia) === "1" &&
										item.hora_i === h &&
										item.pack_id === null && (
											<ActivitatWeek
												color={color}
												activitat={item}
												setFieldValue={setFieldValue}
												values={values}
												setContinuar={setContinuar}
												key={item.id}
											/>
										)
									);
								})}
							</td>
							<td width={matches ? "20%" : "100%"} style={{ verticalAlign: "top" }}>
								{activitats?.map((item) => {
									return (
										String(item.dia) === "2" &&
										item.hora_i === h &&
										item.pack_id === null && (
											<ActivitatWeek
												color={color}
												activitat={item}
												setFieldValue={setFieldValue}
												values={values}
												setContinuar={setContinuar}
												key={item.id}
											/>
										)
									);
								})}
							</td>
							<td width={matches ? "20%" : "100%"} style={{ verticalAlign: "top" }}>
								{activitats?.map((item) => {
									return (
										String(item.dia) === "3" &&
										item.hora_i === h &&
										item.pack_id === null && (
											<ActivitatWeek
												color={color}
												activitat={item}
												setFieldValue={setFieldValue}
												values={values}
												setContinuar={setContinuar}
												key={item.id}
											/>
										)
									);
								})}
							</td>
							<td width={matches ? "20%" : "100%"} style={{ verticalAlign: "top" }}>
								{activitats?.map((item) => {
									return (
										String(item.dia) === "4" &&
										item.hora_i === h &&
										item.pack_id === null && (
											<ActivitatWeek
												color={color}
												activitat={item}
												setFieldValue={setFieldValue}
												values={values}
												setContinuar={setContinuar}
												key={item.id}
											/>
										)
									);
								})}
							</td>
							<td width={matches ? "20%" : "100%"} style={{ verticalAlign: "top" }}>
								{activitats?.map((item) => {
									return (
										String(item.dia) === "5" &&
										item.hora_i === h &&
										item.pack_id === null && (
											<ActivitatWeek
												color={color}
												activitat={item}
												setFieldValue={setFieldValue}
												values={values}
												setContinuar={setContinuar}
												key={item.id}
											/>
										)
									);
								})}
							</td>
						</tr>
					))}
				</table>
			)}
			{!matches && (
				<Grid container spacing={1}>
					<Grid item xs={12} md>
						{activitats?.map((item) => {
							return (
								String(item.dia) === "1" &&
								item.pack_id === null && (
									<ActivitatWeek
										color={color}
										activitat={item}
										setFieldValue={setFieldValue}
										values={values}
										setContinuar={setContinuar}
										key={item.id}
									/>
								)
							);
						})}
					</Grid>
					<Grid item xs={12} md>
						{activitats?.map((item) => {
							return (
								String(item.dia) === "2" &&
								item.pack_id === null && (
									<ActivitatWeek
										color={color}
										activitat={item}
										setFieldValue={setFieldValue}
										setContinuar={setContinuar}
										values={values}
										key={item.id}
									/>
								)
							);
						})}
					</Grid>
					<Grid item xs={12} md>
						{activitats?.map((item) => {
							return (
								String(item.dia) === "3" &&
								item.pack_id === null && (
									<ActivitatWeek
										color={color}
										activitat={item}
										setFieldValue={setFieldValue}
										setContinuar={setContinuar}
										values={values}
										key={item.id}
									/>
								)
							);
						})}
					</Grid>
					<Grid item xs={12} md>
						{activitats?.map((item) => {
							return (
								String(item.dia) === "4" &&
								item.pack_id === null && (
									<ActivitatWeek
										color={color}
										activitat={item}
										setFieldValue={setFieldValue}
										setContinuar={setContinuar}
										values={values}
										key={item.id}
									/>
								)
							);
						})}
					</Grid>
					<Grid item xs={12} md>
						{activitats?.map((item) => {
							return (
								String(item.dia) === "5" &&
								item.pack_id === null && (
									<ActivitatWeek
										color={color}
										activitat={item}
										setFieldValue={setFieldValue}
										setContinuar={setContinuar}
										values={values}
										key={item.id}
									/>
								)
							);
						})}
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default ActivitatsWeek;
