import React, { useEffect, useState } from "react";
import { Box, Container, Grid, makeStyles, TextField, Button, Select, InputLabel, FormControl, CircularProgress } from "@material-ui/core";
import Page from "../../../components/Page";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { getActivitat, getEscoles, getPacks, updateActivitat } from "../../../database/API";
import { useSnackbar } from "notistack";
import Title from "../../../components/Title";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "white",
		minHeight: "100%",
	},
	container: {
		paddingTop: 47,
	},
	// "@global": {
	//     html: {
	//         width: "100%",
	//         height: "100%",
	//     },
	// },
	formControl: {
		marginTop: 16,
		marginBottom: 15,
	},
}));

const ActivitatsEdit = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [escoles, setEscoles] = useState([]);
	const [activitat, setActivitat] = useState();
	const [packs, setPacks] = useState();
	const [loading, setLoading] = useState(true);
	const [dies, setDies] = useState(false);
	const { key } = useParams();

	useEffect(() => {
		const get = async () => {
			const { activitat } = await getActivitat(key);
			setActivitat(activitat);
			setLoading(false);
		};
		get();
	}, [key]);

	useEffect(() => {
		const get = async () => {
			const { escoles } = await getEscoles();
			const { packs } = await getPacks();
			setPacks(packs);
			setEscoles(escoles);
		};
		get();
	}, []);

	const update = async (values) => {
		const message = await updateActivitat(values, key);
		enqueueSnackbar(message, {
			variant: "success",
		});
		navigate(-1, { state: values });
	};

	return (
		<Page className={classes.root} title={"Editar activitat"}>
			<Container maxWidth={false} className={classes.container}>
				{loading ? (
					<CircularProgress />
				) : (
					<Formik
						initialValues={{
							nom: activitat?.nom,
							curs: activitat?.curs,
							escola_id: activitat?.escola_id,
							pack_id: activitat?.pack_id,
							hora_i: activitat?.hora_i,
							hora_f: activitat?.hora_f,
							dia: activitat?.dia,
							descripcio: activitat?.descripcio ? activitat?.descripcio : "",
							preu: activitat?.preu,
							preu_soci: activitat?.preu_soci,
							imparteix: activitat?.imparteix,
							places: activitat?.places,
							publicada: String(activitat?.publicada) === "1" ? true : false,
							setmanal: String(activitat?.setmanal) === "1" ? true : false,
						}}
						validationSchema={Yup.object().shape({
							nom: Yup.string().required("El nom és obligatòri").max(25, "Has superat el límit"),
							descripcio: Yup.string().notRequired().max(50, "Has superat el límit"),
						})}
						onSubmit={(values) => {
							update(values);
						}}
					>
						{({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
							<form onSubmit={handleSubmit}>
								<Grid container spacing={3}>
									<Grid item md={8}>
										<Box mb={3}>
											<Title title={"Editar activitat"} subtitle={"Entra les dades per editar l'activitat"} />
										</Box>
									</Grid>
									<Grid item md={4} style={{ textAlign: "right" }}>
										<FormControlLabel
											control={<Checkbox checked={values.publicada} onChange={handleChange} name="publicada" />}
											label="Publicada"
										/>
									</Grid>
								</Grid>
								<Grid container spacing={3}>
									<Grid item md={3}>
										<TextField
											error={Boolean(touched.nom && errors.nom)}
											fullWidth
											helperText={touched.nom && errors.nom}
											label="Nom"
											margin="normal"
											variant="outlined"
											name="nom"
											onBlur={handleBlur}
											onChange={handleChange}
											type="text"
											value={values.nom}
										/>
									</Grid>
									<Grid item md={3}>
										<FormControl
											className={classes.formControl}
											variant="outlined"
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
										>
											<InputLabel htmlFor="escola_id">Escola</InputLabel>
											<Select
												native
												value={values.escola_id}
												onChange={handleChange}
												onBlur={handleBlur}
												label="Escola"
												name="escola_id"
												InputLabelProps={{
													shrink: true,
												}}
												inputProps={{
													name: "escola_id",
													id: "escola_id",
												}}
											>
												<option value={0} key={0}></option>
												{escoles?.map((escola) => {
													return (
														<option value={escola.id} key={escola.id}>
															{escola.nom}
														</option>
													);
												})}
											</Select>
										</FormControl>
									</Grid>
									<Grid item md={2}>
										<TextField
											error={Boolean(touched.curs && errors.curs)}
											fullWidth
											helperText={touched.curs && errors.curs}
											label="Curs"
											margin="normal"
											name="curs"
											onBlur={handleBlur}
											onChange={handleChange}
											variant="outlined"
											type="text"
											value={values.curs}
										/>
									</Grid>
									<Grid item md={2}>
										<TextField
											error={Boolean(touched.preu && errors.preu)}
											fullWidth
											helperText={touched.preu && errors.preu}
											label="Preu (€)"
											margin="normal"
											name="preu"
											onBlur={handleBlur}
											onChange={handleChange}
											variant="outlined"
											type="double"
											value={values.preu}
										/>
									</Grid>
									<Grid item md={2}>
										<TextField
											error={Boolean(touched.preu_soci && errors.preu_soci)}
											fullWidth
											helperText={touched.preu_soci && errors.preu_soci}
											label="Preu socis (€)"
											margin="normal"
											name="preu_soci"
											onBlur={handleBlur}
											onChange={handleChange}
											variant="outlined"
											type="double"
											value={values.preu_soci}
										/>
									</Grid>
								</Grid>

								<Grid container spacing={3}>
									<Grid item md={2}>
										<TextField
											error={Boolean(touched.hora_i && errors.hora_i)}
											fullWidth
											helperText={touched.hora_i && errors.hora_i}
											label="Hora Inici"
											margin="normal"
											name="hora_i"
											onBlur={handleBlur}
											onChange={handleChange}
											type="time"
											value={values.hora_i}
											variant="outlined"
										/>
									</Grid>
									<Grid item md={2}>
										<TextField
											error={Boolean(touched.hora_f && errors.hora_f)}
											fullWidth
											helperText={touched.hora_f && errors.hora_f}
											label="Hora Final"
											margin="normal"
											name="hora_f"
											onBlur={handleBlur}
											onChange={handleChange}
											variant="outlined"
											type="time"
											value={values.hora_f}
										/>
									</Grid>
									<Grid item md={2}>
										<TextField
											error={Boolean(touched.places && errors.places)}
											fullWidth
											helperText={touched.places && errors.places}
											label="Límit de places"
											margin="normal"
											name="places"
											onBlur={handleBlur}
											onChange={handleChange}
											variant="outlined"
											type="number"
											value={values.places}
										/>
									</Grid>
									<Grid item md={3}>
										<FormControl
											className={classes.formControl}
											variant="outlined"
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
										>
											<InputLabel htmlFor="dia">Dia de la setmana</InputLabel>
											<Select
												native
												value={values.dia}
												onChange={handleChange}
												onBlur={handleBlur}
												label="Dia de la setmana"
												name="dia"
												InputLabelProps={{
													shrink: true,
												}}
												inputProps={{
													name: "dia",
													id: "dia",
												}}
												disabled={dies}
											>
												<option value=""></option>
												<option value="1">Dilluns</option>
												<option value="2">Dimarts</option>
												<option value="3">Dimecres</option>
												<option value="4">Dijous</option>
												<option value="5">Divendres</option>
												<option value="6">Dissabte</option>
												<option value="0">Diumenge</option>
											</Select>
										</FormControl>
										<FormControlLabel
											control={
												<Checkbox
													checked={values.setmanal}
													onChange={() => {
														setFieldValue("setmanal", !values.setmanal);
														setFieldValue("dia", "");
														setDies(!values.setmanal);
													}}
													name="setmanal"
												/>
											}
											label="Activitat diaria"
										/>
									</Grid>
									<Grid item md={3}>
										<TextField
											error={Boolean(touched.imparteix && errors.imparteix)}
											fullWidth
											helperText={touched.imparteix && errors.imparteix}
											label="Qui l'imparteix"
											margin="normal"
											name="imparteix"
											onBlur={handleBlur}
											onChange={handleChange}
											variant="outlined"
											type="text"
											value={values.imparteix}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={3}>
									<Grid item md={6}>
										<TextField
											error={Boolean(touched.descripcio && errors.descripcio)}
											fullWidth
											helperText={touched.descripcio && errors.descripcio}
											label="Descripció"
											margin="normal"
											name="descripcio"
											onBlur={handleBlur}
											onChange={handleChange}
											type="text"
											value={values.descripcio}
											variant="outlined"
										/>
									</Grid>
									<Grid item md={6}>
										<FormControl
											className={classes.formControl}
											variant="outlined"
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
										>
											<InputLabel htmlFor="pack_id">Pack</InputLabel>
											<Select
												native
												value={values.pack_id}
												onChange={handleChange}
												onBlur={handleBlur}
												label="Pack"
												name="pack_id"
												InputLabelProps={{
													shrink: true,
												}}
												inputProps={{
													name: "pack_id",
													id: "pack_id",
												}}
											>
												<option value={""}></option>
												{packs?.map((pack) => {
													return (
														<option value={String(pack.id)} key={pack.id}>
															{pack.nom}
														</option>
													);
												})}
											</Select>
										</FormControl>
									</Grid>
								</Grid>

								<Box my={4}>
									<Button color="primary" fullWidth size="large" type="submit" variant="contained">
										{"Guardar"}
									</Button>
								</Box>
							</form>
						)}
					</Formik>
				)}
			</Container>
		</Page>
	);
};

export default ActivitatsEdit;
