import React from "react";
import { Box, Hidden, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import moment from "moment/min/moment-with-locales";
import clsx from "clsx";
import { CheckCircle } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	activitat: {
		cursor: "pointer",
		padding: 15,
		backgroundColor: "white",
		marginTop: 8,
		border: "5px solid white",
		transition: "0.2s",
		position: "relative",
		height: 300,
	},
	bloc: {
		paddingBottom: 25,
	},
	textAlt: {
		color: theme.palette.small.main,
		fontSize: "0.9rem",
	},
	active: {
		border: "5px solid " + theme.palette.success.main,
	},
	check: {
		fill: theme.palette.success.main,
		position: "absolute",
		right: 15,
	},
	plenaText: {
		backgroundColor: theme.palette.error.main,
		borderRadius: 20,
		padding: 10,
	},
	dia: {
		position: "absolute",
		right: -5,
		top: -5,
		padding: 10,
	},
	diaText: {
		color: "white",
		textTransform: "capitalize",
	},
}));

const ActivitatWeek = (props) => {
	const classes = useStyles();
	const { activitat, color, setFieldValue, values, setContinuar } = props;
	const matches = useMediaQuery("(min-width:960px)");

	return (
		<Tooltip
			title={
				Number(activitat?.alumnes_count) >= Number(activitat?.places)
					? "Activitat plena. Contacta amb nosaltres."
					: String(activitat?.publicada) === "0"
					? "Activitat no oberta a inscripcions."
					: ""
			}
			// disableHoverListener={
			//     Number(activitat?.alumnes_count) !==
			//         Number(activitat?.places) && String(activitat?.publicada) === "0"
			// }
			arrow
		>
			<Box
				className={clsx(classes.activitat, values.activitat?.includes(activitat.id) && classes.active)}
				style={{
					opacity: Number(activitat?.alumnes_count) >= Number(activitat?.places) ? 0.5 : String(activitat?.publicada) === "1" ? 1 : 0.5,
				}}
				onClick={() => {
					if (String(activitat?.publicada) === "1") {
						if (values.activitat?.includes(activitat?.id)) {
							let newValues = values.activitat.filter(function (element) {
								return element !== activitat?.id;
							});
							setFieldValue("activitat", newValues);
							if (newValues.length > 0) {
								setContinuar(true);
							} else {
								setContinuar(false);
							}
						} else {
							setFieldValue("activitat", [...values.activitat, activitat?.id]);
							setContinuar(true);
						}
					}
				}}
			>
				<Box className={classes.bloc}>
					<Typography variant="body1" style={{ fontSize: "0.9rem", fontWeight: 700 }}>
						{moment(activitat.hora_i, "HH:mm:ss").format("H:mm")} - {moment(activitat.hora_f, "HH:mm:ss").format("H:mm")} h
					</Typography>
				</Box>
				<Box className={classes.bloc}>
					<Typography variant="caption" style={{ color: color, fontSize: 12 }}>
						{activitat.curs}
					</Typography>
					<Typography variant="h4" style={{ color: color }}>
						{activitat.nom}
					</Typography>
					<Typography variant="caption" style={{ color: color, fontSize: 12 }}>
						{activitat.descripcio}
					</Typography>
				</Box>
				<Box className={classes.bloc}>
					{Number(activitat.preu_soci) ? (
						<Typography variant="body1" className={classes.textAlt}>
							{activitat.preu_soci} € mensuals (socis AFA)
						</Typography>
					) : (
						""
					)}
					{Number(activitat.preu) ? (
						<Typography variant="body1" className={classes.textAlt}>
							{activitat.preu} € mensuals
						</Typography>
					) : (
						""
					)}
				</Box>

				<Typography variant="body1" className={classes.textAlt}>
					{activitat.imparteix}
				</Typography>
				<Typography variant="body1" className={classes.textAlt}>
					Places: {activitat?.alumnes_count}/{activitat?.places}
				</Typography>
				{Number(activitat?.alumnes_count) >= Number(activitat?.places) && (
					<Typography variant="body1" className={classes.textAlt} style={{ fontSize: 12 }}>
						Places plenes. Si la selecciones, et posarem a la llista d'espera
					</Typography>
				)}
				{values.activitat?.includes(activitat.id) && <CheckCircle className={classes.check} style={{ top: matches ? 15 : 45 }} />}
				<Hidden mdUp>
					<Box className={classes.dia} style={{ backgroundColor: color }}>
						<Typography variant="body1" className={classes.diaText}>
							{activitat.dia ? moment(activitat.dia, "e").locale("ca").format("dddd") : "Diària"}
						</Typography>
					</Box>
				</Hidden>
			</Box>
		</Tooltip>
	);
};

export default ActivitatWeek;
